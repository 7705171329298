@mixin mat-icon-pulsating($theme) {
  $primary: map-get($theme, primary);

  mat-icon.pulsating {
    $color: mat-color($primary);
    color: $color;
    opacity: 1;
    text-shadow: 0 0 2px rgba(0,0,0,0.2);
    overflow: hidden;

    animation-duration: 0.7s;
    animation-fill-mode: forwards;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-name: pulseTextColor;
    animation-timing-function: linear;
  }
}
