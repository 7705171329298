@import '~@angular/material/theming';

@mixin present-card-theme($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    .present-card {
        .mat-form-field-wrapper {
            padding: 0;
        }

        .mat-form-field-infix {
            width: auto;
        }

        &.taken {
            opacity: 0.5;
        }

        .present-card-overlay {
            opacity: 0;
            border-radius: 4px;
            border: 2px solid mat-color($primary);
            z-index: 10;

            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

            pointer-events: none;
            transition: all 0.1s linear;
        }

        &.owning, &.taken-readonly {
          .present-card-overlay {
            opacity: 1;
            transition: opacity 0.3s linear;
          }
        }
    }
}
