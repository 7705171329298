@import '~@angular/material/theming';

@mixin ngx-uploader-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $theme-primary-color: mat-color($primary, 500);
  $theme-accent-color: mat-color($accent, 500);

  .wd-file-dropper-zone {
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $theme-primary-color;
    background: mat-color($primary, 100);
    border-radius: 6px;

    margin-top: 12px;
    margin-bottom: 12px;

    &.ngx-file-drop__drop-zone--over {
      background: mat-color($primary, 50) !important;
    }
  }

  .wd-file-dropper-content {
    color: $theme-primary-color;
  }

}
