@import '~@angular/material/theming';

@mixin generate-color-classes($name, $color, $hue: '') {
    $suffix: "-" + $hue;
    @if ($hue == '') {
        $suffix: '';
    }

  .#{$name + $suffix} {
    background-color: $color;
  }

  .text-#{$name + $suffix} {
    color: $color;
  }
}

@mixin generate-palette-classes($name, $palette) {
    @include generate-color-classes($name, mat-color($palette));

    $hues: 100, 200, 300, 400, 500, 600, 700, 800, 900, 'A100', 'A200', 'A400', 'A700';
    @each $hue in $hues {
        @include generate-color-classes($name, mat-color($palette, $hue), $hue);
    }
}

@mixin color-classes-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  @include generate-palette-classes('primary', $primary);
  @include generate-palette-classes('accent', $accent);
  @include generate-palette-classes('success', mat-palette($mat-green));
  @include generate-palette-classes('error', mat-palette($mat-red));
}
