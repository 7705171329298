@mixin wd-image-dialog() {
  .wd-image-dialog {
    .mat-dialog-container {
      background: none;
      box-shadow: none;
      border-radius: none;
      padding: 0;
    }
  }
}

@mixin wd-image-dialog-theme($theme) {
  .wd-image-dialog-backdrop {
    $background: map-get($theme, background);
    background: map-get($background, background);
  }
}
