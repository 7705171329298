@import '../../mixins/media-breakpoints';

.wd-content .mat-list .mat-list-item .mat-list-item-content {
  @include media-breakpoint('lt-md') {
    padding: 0 8px;
  }
  @include media-breakpoint('lt-sm') {
    padding: 0 4px;
  }
}
