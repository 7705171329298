@import '~@angular/material/theming';

$md-mcgpalette0: (
  50 : #f8fcfb,
  100 : #eef8f5,
  200 : #e3f3ef,
  300 : #d7eee9,
  400 : #cfebe4,
  500 : #c6e7df,
  600 : #c0e4db,
  700 : #b9e0d7,
  800 : #b1ddd2,
  900 : #a4d7ca,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #ffffff,
  A700 : #ffffff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

@mixin mat-menu-item-active($theme) {
  $primary: map-get($theme, primary);

  .mat-menu-item.active {
    background: rgba(mat-color($primary, 100), 0.5);

    &,
    .mat-icon:not([color]) {
      color: mat-color($primary);
    }
  }

  [mat-button].active {
    background: #b89e14;
    color: #fff;
  }
}