@import 'mixins/color-classes';
@import 'mixins/wd-image-dialog';

@import 'themes/index';
@import 'themes/page-loader';

@mixin core-theme($theme) {
  // Include theme styles for core and each component used in your app.
  // Alternatively, you can import and @include the theme mixins for each component
  // that you are using.
  /*@include angular-material-theme($theme);

  @include wd-image-dialog-theme($theme);
  @include color-classes-theme($theme);
  @include component-themes($theme);
  @include page-loader-theme($theme);*/

  /* For use in src/lib/core/theming/_palette.scss */
  /* For use in src/lib/core/theming/_palette.scss */
  $md-mcgpalette0: (50 : #f8fcfb,
    100 : #eef8f5,
    200 : #e3f3ef,
    300 : #d7eee9,
    400 : #cfebe4,
    500 : #c6e7df,
    600 : #c0e4db,
    700 : #b9e0d7,
    800 : #b1ddd2,
    900 : #a4d7ca,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
    contrast: (50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #000000,
      600 : #000000,
      700 : #000000,
      800 : #000000,
      900 : #000000,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
    ));


  /* For use in src/lib/core/theming/_palette.scss */
  $md-mcgpalette1: (50 : #f6f3e3,
    100 : #eae2b9,
    200 : #dccf8a,
    300 : #cdbb5b,
    400 : #c3ad37,
    500 : #b89e14,
    600 : #b19612,
    700 : #a88c0e,
    800 : #a0820b,
    900 : #917006,
    A100 : #ffeebe,
    A200 : #ffe08b,
    A400 : #ffd258,
    A700 : #ffcb3e,
    contrast: (50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #000000,
      400 : #000000,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #000000,
      A400 : #000000,
      A700 : #000000,
    ));





  $my-app-primary: mat-palette($md-mcgpalette0);
  $my-app-accent: mat-palette($md-mcgpalette1);
  $my-app-warn: mat-palette($mat-deep-orange);
  $my-app-theme: mat-light-theme($my-app-primary, $my-app-accent, $my-app-warn);
  @include angular-material-theme($my-app-theme);
  @include wd-image-dialog-theme($my-app-theme);
  @include color-classes-theme($my-app-theme);
  @include component-themes($my-app-theme);
  @include page-loader-theme($my-app-theme);
}