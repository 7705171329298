body {
  position: fixed;
}

.wd-content {
    max-width: 960px;
    width: 960px;
    margin: auto;
    padding: 16px;

    @media (max-width: 992px) {
        max-width: 100%;
        width: 100%;
    }

    &.no-padding {
        padding: 0;
    }
}

.wd-intro {
    margin-bottom: 32px !important;
    font-size: 110%;

    text-align: center;
}

.wd-intro:not(:first-child) {
  margin-top: 32px !important;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: 700 !important;
}

.text-handwritten {
  font-family: 'Rochester', cursive !important;
  letter-spacing: 0 !important;
}

.text-headline {
  font-size: 32px;
  line-height: 32px;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
  display: block;
}

.text-subheadline {
  font-size: 20px;
  line-height: 20px;
  margin-top: 32px !important;
  margin-bottom: 32px !important;
  display: block;
}

.text-uppercase {
  text-transform: uppercase;
}

mat-form-field.wd-mat-form-field__no-margin > .mat-form-field-wrapper {
    margin: 0;
    padding: 0;
}

mat-icon.mat-icon-32 {
  width: 32px;
  height: 32px;

  font-size: 32px;
  line-height: 32px;

  margin: 8px;
}

mat-card-header.single-row {
  align-items: center;
  margin-bottom: 12px;

  mat-card-title {
    margin-bottom: 0 !important;
  }
}

mat-icon.mat-icon-64 {
  width: 64px;
  height: 64px;

  font-size: 64px;
  line-height: 64px;

  margin: 16px;
}

[mat-card-avatar].square {
  border-radius: 0;
}

mat-icon {
  overflow: hidden;
}

mat-form-field.wd-form-field__no-padding > .mat-form-field-wrapper {
  padding-bottom: 0;
}

@keyframes pulseTextColor {
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0.5;
  }
}
