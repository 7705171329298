.wd-radio-group {
  display: inline-flex;
  flex-direction: column;
  width: 100%;

  mat-radio-button {
    margin-bottom: 4px;
  }
}

.wd-radio-button {
  margin: 12px 5px;

  .mat-radio-label-content {
    white-space: normal;
  }
}
